import { show } from 'js-snackbar'

$('#api-tel').on('input', function () {
  const tel = $(this).val()
  const key = $('#api-key').val()
  const pass = $('#api-password').val()

  const baseUrl = window.location.origin

  const result = baseUrl + '/api/check?api_key=' + key + '&password=' + pass + '&number=' + tel

  $('#api-url').val(result)
})

window.copyToClipboard = function () {
  const input = $('#api-url').val()
  navigator.clipboard.writeText(input)
  show({
    text: 'Copied to clipborad!',
    pos: 'top-right',
    backgroundColor: '#42306f'
  })
}

$('#generate-api-form').submit(function (event) {
  event.preventDefault()
  $('#user-api-modal-other').modal('show')
})

$('#api-gen-confirm').click(function (event) {
  event.preventDefault()
  $('#user-api-modal-other').modal('hide')
})
