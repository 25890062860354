const showModal = function (title, content, type) {
  $('#global-modal-label').text(title)
  $('#global-modal-body').text(content)

  switch (type) {
    case 'success':
      $('#global-modal-sign').html(
      )
      break
    case 'fail':
      $('#global-modal-sign').html(
      )
      break
    default:
      $('#global-modal-sign').html(
      )
  }

  $('#global-modal').modal('show')
}
export { showModal }
