import $ from 'jquery'
window.$ = window.jQuery = $

require('./bootstrap')
require('./modal')
require('./home')
require('./pricing')
require('./dashboard')
require('./user-api')
require('./legacy')
