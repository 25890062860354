import Dropzone from 'dropzone'

if ($('#drop-file-csv').length) {
  new Dropzone('#drop-file-csv', { // eslint-disable-line no-new
    paramName: 'userfile',
    maxFiles: 1,
    maxFilesize: 500,
    previewTemplate: $('#drop-file-csv-template').html(),
    acceptedFiles: '.csv, .xlsx, .xls, .txt',
    maxfilesexceeded: function (file) {
      this.removeAllFiles()
      this.addFile(file)
    },
    init: function () {
      const dz = this

      $('#batch-modal').modal({
        backdrop: 'static',
        keyboard: false
      })

      $('#drop-file-csv-template').hide()

      this.on('success', function (file, response) {
        $('#batch-modal').modal('show')
        $('#file-id').val(response.file_id)
        $('#tps_csv_column').html('<option value="0">Choose</option>')
        $.each(response.columns, function (i, value) {
          $('#tps_csv_column').append('<option value="' + i + '" ' + '>' + value + '</option>')
        })
      })

      this.on('addedfile', function (file) {
        $('.dz-success-message .success').html('Uploading your file, Please wait.')
        $('#uu-submit-column-container').hide()
        $('#download-csv-btns').hide()
        $('#uu-submit-column-form-alert').html('').hide()
      })

      this.on('drop', function (file) {
        $('#download-csv-btns').hide()
        $('#uu-submit-column-form-alert').html('').hide()
      })

      $('#batch-modal').on('hidden.bs.modal', function () {
        dz.removeAllFiles()
        $('#uu-submit-column-container').hide()
        $('#uu-submit-column-form-alert').hide()
        $('.tps-download-file').data('href', '')
        $('#uu-submit-column-form-control-container').show()
        $('#drop-file-csv .dz-upload').addClass('active')
        $('#uu-file-upload-error-text').html('')
      })

      this.on('error', function (file, response) {
        let message = "Oh no! We weren't able to receive your file. Please try again and if this problem persists contact support@tpsunlimited.com"
        if (response.message) {
          message = response.message
        }
        $(file.previewElement).find('.dz-error-message').text(message)
      })
    }
  })
}

const showFinal = function (data) {
  $('#final-modal').modal({
    backdrop: 'static',
    keyboard: false
  })
  $('#final-modal').modal('show')

  $('#final-modal').on('hidden.bs.modal', function () {
    location.reload()
  })
}

$('#batch-process-form').on('submit', function (e) {
  e.preventDefault()

  const $this = $(this)
  const url = $this.prop('action')
  const method = $this.prop('method')
  const formData = $this.serialize()
  const $btn = $this.find('button[type=submit]')
  $.ajax({
    url,
    data: formData,
    type: method,
    beforeSend: function () {
      console.log('before send!')
      $btn.prop('disabled', true)
      $('#uu-submit-column-progress').show()
    },
    complete: function () {
      $btn.prop('disabled', false)
      $('#uu-submit-column-progress').hide()
    },
    success: function (data) {
      $('#batch-modal').modal('toggle')
      showFinal(data)
    },
    error: function () {
      console.log('fail fail fail fail fail fail fail fail ')
    }
  })
})

const refreshFileStatus = function () {
  $('.file-result').each(function () {
    const $this = $(this)
    const fileId = $this.find('.file-id').first().val()
    const message = $this.text()

    if (message.indexOf('File is being processed') !== -1) {
      fetch('/dashboard/file', {
        method: 'POST',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          file_id: fileId
        })
      }).then(response => {
        if (response.status === 201) {
          const resultsDiv = $this.find('.file-result-content').first()
          resultsDiv.html('<a class="no-tps-btn btn btn-xs btn-success tb-button" href="' + window.location.origin + '/batch/download/1/' + fileId + '">Not on TPS</a><a class="on-tps-btn btn btn-xs btn-info tb-button" href="' + window.location.origin + '/batch/download/2/' + fileId + '">On TPS</a>')
        }
      }).catch(error => {
        console.log(error)
      })
    }
  })
}

if ($('#files-table').length) {
  setInterval(refreshFileStatus, 5000)
}

$('.delete-file-btn').on('click', function (e) {
  const fileId = $(this).parent().parent().find('.file-id').val()
  $('#custom-delete-modal').removeClass('hidden')
  const baseUrl = window.location.origin
  $('#custom-delete-form').attr('action', baseUrl + '/batch/delete/' + fileId)
})

$('#close-modal-btn').on('click', function () {
  $('#custom-delete-modal').addClass('hidden')
})

$('.legacy-del-btn').on('click', function (e) {
  const fileId = $(this).parent().parent().find('.file-id').val()
  $('#custom-delete-modal').removeClass('hidden')
  const baseUrl = window.location.origin
  $('#custom-delete-form').attr('action', baseUrl + '/batch/delete/legacy/' + fileId)
})
