import { showModal } from './modal'

$(document).ready(function () {
  $('.navbar-expand-md .navbar-toggler').click(function () {
    $('.nav').slideToggle()
  })

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const checkout = urlParams.get('checkout')

  if (checkout === 'success') {
    $('#success-modal').modal('show')
  }
})

window.onload = function () {
  const homeCheckSingleNumberForm = document.getElementById('home-check-single-number-form')
  $('#home-modal-btn').hide()
  $('#notFound').hide()
  $('#alert').hide()
  $('#broken').hide()
  $('#modal-sign-up-bt').hide()
  $('#found').hide()
  if (homeCheckSingleNumberForm) {
    homeCheckSingleNumberForm.addEventListener('submit', async (e) => {
      e.preventDefault()
    })

    if (_submitForm) { // eslint-disable-line
      _submitForm = async function (e) { // eslint-disable-line

        // ---------------------------------------
        // Hide number checker form on submit
        // ---------------------------------------
        $('#home-check-single-number-form').hide()
        // ---------------------------------------

        const phoneNumber = document.getElementById('home-number-check').value
        await $.ajax({
          method: 'POST',
          url: '/check-single-number',
          data: {
            phone: phoneNumber,
            'g-recaptcha-response': $('#g-recaptcha-response').val(),
            _token: $('input[name=_token]').val()
          },
          dataType: 'json',
          success: function (data) {
            if (!data.found) {
              showModal('Number checked: ' + phoneNumber, '', 'success')
              $('#notFound').show()
              $('#broken').hide()
              $('#home-modal-btn').show()
              $('#modal-sign-up-bt').hide()
              $('#alert').hide()
              $('#found').hide()
            } else {
              showModal('Number checked: ' + phoneNumber, '', 'fail')
              $('#notFound').hide()
              $('#found').show()
              $('#broken').hide()
              $('#home-modal-btn').show()
              $('#modal-sign-up-bt').hide()
              $('#alert').hide()
            }

            homeCheckSingleNumberForm.reset()

            grecaptcha.reset() // eslint-disable-line no-undef
          },
          error: function (data) {
            if (data.status === 429) {
              showModal('Alert',
                '', 'fail')
              $('#alert').show()
              $('#notFound').hide()
              $('#broken').hide()
              $('#modal-sign-up-bt').show()
              $('#home-modal-btn').hide()
              $('#found').hide()
            } else {
              showModal('Something went wrong...', '', 'fail')
              $('#home-modal-btn').show()
              $('#broken').show()
              $('#found').hide()
              $('#modal-sign-up-bt').hide()
              $('#notFound').hide()
            }

            homeCheckSingleNumberForm.reset()
            grecaptcha.reset() // eslint-disable-line no-undef
          }
        })
      }
    }
  }
}

// ---------------------------------------
// Show number checker form on modal button click
// ---------------------------------------

const close = function (click) {
  $(click).click(function () {
    $('#home-check-single-number-form').show()
  })
}

close('#home-modal-btn')
close('#modal-close')
