$('.pricing-text-a').addClass('on')

$('#price-toggle').on('change', function (e) {
  $('.pricing-text-a').toggleClass('on')
  $('.pricing-text-b').toggleClass('on')

  for (let i = 1; i <= 4; i++) {
    if ($('#pricing-card' + i).css('display') === 'none') {
      $('#pricing-card' + i).show()
    } else {
      $('#pricing-card' + i).hide()
    }
  }
})
