const countdownTimer = function (timestamp) {
  const difference = timestamp * 1000 - (new Date()).getTime()
  let remaining = 0

  if (difference > 0) {
    let hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
    let minutes = Math.floor((difference / 1000 / 60) % 60)
    let seconds = Math.floor((difference / 1000) % 60)

    if (hours < 10) {
      hours = '0' + hours
    }

    if (minutes < 10) {
      minutes = '0' + minutes
    }

    if (seconds < 10) {
      seconds = '0' + seconds
    }

    remaining = hours + ':' + minutes + ':' + seconds
  }

  return remaining
}

const refreshTimers = function (element, timestamp) {
  const countdown = countdownTimer(timestamp)
  if (countdown === 0) {
    location.reload()
  }
  element.html(countdown)
}

const initCountdownTimers = function () {
  if ($('.file-timer').length > 0) {
    $('.file-timer').each(function (index) {
      const $this = $(this)
      const timestampEnd = parseInt($this.data('countdown-timer-endtime'))

      refreshTimers($this, timestampEnd)
    })
  }
}

if ($('#legacy-files-table').length) {
  initCountdownTimers()
  setInterval(initCountdownTimers, 5000)
}
